import { GetterTree } from 'vuex';
import { CartState, RootState } from '../types';
import { state as restaurantState } from '../restaurant/restaurant';
import { state as suitesState } from '../suites/suites';
import i18n from '@/i18n';

export const getters: GetterTree<CartState, RootState> = {
	getItems(state): OrderItem[] {
		return state.items;
	},

	getTemporaryQuantity(state): Object {
		return state.temporarySelectedItem.quantity;
	},

	getOrderPrice(state): string {
		return state.temporarySelectedItem.orderPrice!.toFixed(2);
	},

	getOrderType: (state) => (detailed: boolean = false) => {
		if(suitesState.preOrdering) {
			return 'catering';
		}
		else if (state.config.genericCatering) {
			return 'generic-catering';
		}
		else if(state.config.takeout) {
			if(detailed) {
				return state.checkout.pickup?.delivery ? 'delivery' : 'pickup';
			}
			return 'take-out';
		}
		else {
			return 'dine-in';
		}
	},

	getMemberOrderPrice(state): string|undefined {
		return state.temporarySelectedItem.memberOrderPrice ? state.temporarySelectedItem.memberOrderPrice.toFixed(2) : undefined;
	},

	getTemporarySelectedItem(state): OrderItem | undefined {
		return state.temporarySelectedItem ? state.temporarySelectedItem : undefined;
	},

	getTemporaryOrderOptions(state): OrderOptionGroup[] | null {
		return state.temporarySelectedItem.orderOptions;
	},

	getSubtotal(state): string {
		return state.costs.subtotal.toFixed(2);
	},

	getPromoDiscount(state): string {
		return state.costs.discount.amount.toFixed(2);
	},

	isMemberDiscountPreTax(state): boolean {
		return state.costs.memberDiscount && state.costs.memberDiscount.preTax;
	},

	getMemberDiscount(state): string {
		return state.costs.memberDiscount && state.costs.memberDiscount.amount.toFixed(2);
	},

	getVoucherAmount(state): string {
		return state.costs.voucher.amount.toFixed(2);
	},

	getSubtotalDiscounted(state): string {
		return state.costs.subtotalDiscounted.toFixed(2);
	},

	getTaxes(state): string {
		return state.costs.taxes.toFixed(2);
	},

	getTaxBrackets(state): TotalBracketTaxes {
		return state.costs.taxBrackets;
	},

	getTaxInfo(state): TaxInfo[] {
		return state.costs.taxInfo;
	},

	getTip(state): string {
		return state.costs.tip.amount.toFixed(2);
	},

	getServiceCharge(state): string | null {
		if(state.costs.serviceCharge.amount > 0) {
			return state.costs.serviceCharge.amount.toFixed(2);
		}
		return null;
	},

	getServiceChargeLabel(state): string {
		if(state.costs.serviceCharge.label) {
			return state.costs.serviceCharge.label;
		}
		return i18n.t('cart.pricing.service_fee_default_label');
	},

	getDeliveryCharge(state): string | null {
		if(state.costs.deliveryCharge.amount > 0) {
			return state.costs.deliveryCharge.amount.toFixed(2);
		}
		return null;
	},

	getTipObject(state): object {
		return state.costs.tip;
	},

	getTotal(state): string {
		return state.costs.total.toFixed(2);
	},

	getTotalWithTip(state): string {
		return state.costs.totalWithTip.toFixed(2);
	},

	getPromoDiscountInfo(state): object {
		return state.costs.discount;
	},

	getVoucherInfo(state): object {
		return state.costs.voucher;
	},

	getTotalNumberOfItems(state): number {
		let totalNumberOfItems: number = 0;
		state.items.forEach((item) => {
			totalNumberOfItems += item.quantity;
		});
		return totalNumberOfItems;
	},

	getPickupInformation(state): CheckoutPickupInfo | null {
		return state.checkout.pickup;
	},

	getSuitesInformation(state): CheckoutSuitesInfo | null {
		return state.checkout.suitesInfo;
	},

	getDeliveryInformation(state): CheckoutDeliveryInfo | null {
		return state.checkout.delivery;
	},

	getErrorValidationInfo(state): ErrorValidation {
		return state.errorValidation;
	},

	getToday(state): string {
		return state.today;
	},

	getDateSelectedByUser(state): boolean {
		return state.dateSelectedByUser;
	},

	getTipSelected(state): boolean {
		return state.tipSelected;
	},

	getLegalCheck(state): boolean {
		return state.legal;
	},

	getTakeoutDateRange(state): string {
		return state.config && state.config.takeoutDateRange;
	},

	getMenuList(state): string[] | null {
		if(state.config && state.config.menuList) {
			return state.config && state.config.menuList.split(',');
		}
		return null;
	},

	getTableNum(state): string | null {
		return state.config && state.config.tableNum;
	},

	getTakeoutSpecificDates(state): string {
		return state.config && state.config.takeoutSpecificDates;
	},

	getLoyaltyProgramUserStatus(state): string | null {
		return state.checkout && state.checkout.loyaltyProgram && state.checkout.loyaltyProgram.userStatus;
	},

	getMemberInfo(state): CheckoutMemberInfo | null {
		return state.checkout && state.checkout.memberInfo;
	},

	getMemberId(state): string | null {
		return state.checkout && state.checkout.memberInfo && state.checkout.memberInfo.id;
	},

	getMemberProvider(state): string | null {
		return state.checkout && state.checkout.memberInfo && state.checkout.memberInfo.provider;
	},

	getContactInformation(state): CheckoutContactInfo {
		return state.checkout.contact;
	},

	getContactName(state): string | null {
		return state.checkout.contact && state.checkout.contact.full_name;
	},

	getInvoiceInformation(state): boolean {
		return state.checkout.invoice;
	},

	getCostCenterInformation(state): string | null {
		return state.checkout.costCenter;
	},

	getPurchaseOrderInformation(state): string | null {
		return state.checkout.purchaseOrder;
	},

	getCardInformation(state): CheckoutCardInfo {
		return state.checkout.card;
	},

	getPaymentMethod(state): string {
		return state.checkout.paymentMethod;
	},

	getChosenCreditCard(state): SavedPaymentOption | null {
		return state.chosenCreditCard;
	},

	cartContainsNonAlcoholicItems(state): boolean {
		let contains: boolean = false;
		state.items.forEach(item => {
			if (!item.alcoholic_beverage) {
				return contains = true;
			}
		});
		return contains;
	},

	isTakeOut(state): boolean {
		return state.config && state.config.takeout === 'true';
	},

	isPickupOnly(state): boolean {
		return state.config && state.config.takeoutType === 'pickup';
	},

	isDeliveryOnly(state): boolean {
		return state.config && state.config.takeoutType === 'delivery';
	},

	isDelivery(state): boolean | null | undefined {
		return state.checkout.pickup && state.checkout.pickup.delivery;
	},

	isGenericCatering(state): boolean {
		return state.config && state.config.genericCatering === 'true' && restaurantState.restaurant.catering;
	},

	isViewOnly(state): boolean {
		return state.config && state.config.viewOnly == 'true';
	},

	isGenericTableLocation(state): boolean {
		return state.config && state.config.genericTableLocation == 'true';
	},

	isUserSavingPaymentMethodOnCheckout(state): boolean {
		return state.checkout.savePaymentMethodOnCheckout;
	},

	showMembershipBanner(state): boolean {
		return state.showMembershipBanner;
	},

	getCheckoutAnsweredQuestions(state): CheckoutCustomQuestion[] | null {
		return state.checkout.questions;
	}
};