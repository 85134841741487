import i18n from '@/i18n';
import store from '../store';

export function addToCartExceedLimit(maxItemsPerCart: number): boolean {
	if(maxItemsPerCart) {
		const items: OrderItem[] = store.getters['cart/getItems'];
		const currentItem: OrderItem = store.getters['cart/getTemporarySelectedItem'];
		let numberOfItems: number = currentItem.quantity;

		items.forEach((item: OrderItem) => {
			numberOfItems += item.quantity;
		});

		return numberOfItems > maxItemsPerCart;
	}
	else {
		return false;
	}
}

export function updateQuantityExceedLimit(maxItemsPerCart: number, payload: any): boolean {
	if(maxItemsPerCart) {
		const items: OrderItem[] = store.getters['cart/getItems'];
		let numberOfItems: number = +payload.quantity;

		items.forEach((item: OrderItem) => {
			if(item.orderId !== payload.id) {
				numberOfItems += +item.quantity;
			}
		});

		return numberOfItems > maxItemsPerCart;
	}
	else {
		return false;
	}
}

/**
 * Calculate quantity of the option we can add, depending of the option's max quantity and the option group's limit
 *
 * @param {OptionGroup} optionGroup
 * @param {Option} option
 * @param {number} receiptItemOptionQuantity
 * @param {number} numberOfOptionsAlreadySelected
 * @return {number}
 */
export function calculateOptionQuantityThatCanBeAdded(optionGroup: OptionGroup, option: Option, receiptItemOptionQuantity: number, numberOfOptionsAlreadySelected: number): number {
	let quantity = receiptItemOptionQuantity;
	if (typeof option.max_quantity === 'number' && quantity > option.max_quantity) {
		quantity = option.max_quantity;
	}
	if (typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && quantity > optionGroup.limit) {
		quantity = optionGroup.limit;
	}
	// If the quantity and the qty of the previous selected options are greater than the limit
	if (typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && (quantity + numberOfOptionsAlreadySelected) > optionGroup.limit) {
		quantity = optionGroup.limit - numberOfOptionsAlreadySelected;
	}
	return quantity;
}

/**
 * Detect which card type it is with regex
 *
 * @param {string} cardNumber
 * @return {string}
 */
export function findCardType(cardNumber: string): string {
	const amex_regex = new RegExp('^3[47][0-9]{0,}$'); // American Express: 34, 37
	const visa_regex = new RegExp('^4[0-9]{0,}$'); // Visa: 4
	const mc_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); // Mastercard: 2221-2720, 51-55
	if(cardNumber.match(amex_regex)) {
		return 'AMEX';
	}
	if(cardNumber.match(mc_regex)) {
		return 'MC';
	}
	if(cardNumber.match(visa_regex)) {
		return 'VISA';
	}
	return '';
}

/**
 * Get the dynamic field value depending on the locale
 * We start by checking if the element contains a localization object.
 * If it does, then we check if there is a localization object for the
 * current locale selected. If not we default to the default value
 * of the element.
 *
 * @param {LocaleElement} element
 * @param {Localization | undefined} localization
 * @param {string} field
 * @param {RestaurantLocale[] | undefined} locales
 * @return {string}
 */
export function getDynamicFieldValue(element: LocaleElement, localization: Localization | undefined, field: string, locales: RestaurantLocale[] | undefined): string {
	const locale = locales && locales.find((locale: RestaurantLocale) => {
		return locale.locale_short === i18n.locale.substring(0,2);
	})!;
	if(locale && localization && i18n?.locale && localization[i18n.locale.substring(0,2)] && localization[i18n.locale.substring(0,2)][field]) {
		return localization[i18n.locale.substring(0,2)][field];
	}
	return element[field];
}


/**
 * Scroll to the element if it is out of the view
 *
 * @param {Element} element
 * @param {ScrollIntoViewOptions} scrollOptions
 * @return {void}
 */
export function scrollIntoViewIfNeeded(element: Element, scrollOptions: ScrollIntoViewOptions): void {
	if (element.getBoundingClientRect().bottom > window.innerHeight || element.getBoundingClientRect().top < 0) {
		element.scrollIntoView(scrollOptions);
	}
}

/**
 * Get the Y positions on the screen of the menus or sections
 *
 * @param {Menu[] | MenuSection[]} elements
 * @return {number[]}
 */
export function getYPositionsOfElements(elements: Menu[] | MenuSection[]): number[] {
	const yPositions: number[] = [];

	elements.forEach((element, sIdx) => {
		const docElement = document.getElementById(`main-divider-${element.slug}-${sIdx}`);
		if (docElement) {
			yPositions.push(docElement.getBoundingClientRect().top - 50);
		} else {
			yPositions.push(-1000);
		}
	});

	return yPositions;
}

/**
 * Get the selected menu or section based on scroll position
 *
 * @param {number[]} yPositions
 * @param {number} windowHeight
 * @return {number} index of the selected element in view
 */
export function getSelectionFromVisibleSections(yPositions: number[], windowHeight: number): number {
	const pageHalfwayPoint = windowHeight / 2;

	if (yPositions[0] > pageHalfwayPoint) {
		return 0;
	}

	for (let i = 0; i < yPositions.length; i++) {
		if (i + 1 === yPositions.length || (yPositions[i] < pageHalfwayPoint && yPositions[i + 1] > pageHalfwayPoint)) {
			return i;
		}
	}
	return 0;
}

/**
	 * Scroll to the respective menu or section selected
	 *
	 * @param {MenuSection} section
	 * @param {number} sIdx
	 * @param {number} topOffset
	 * @return {void}
	 */
export function scrollToElement(section: MenuSection, sIdx: number, topOffset: number): void {
	const sectionHeaderElement = document.getElementById(`main-divider-${section.slug}-${sIdx}`);
	const navBar = document.getElementById('menu-nav-container');

	if (section && sectionHeaderElement && navBar) {
		const navBarStyle = window.getComputedStyle(navBar);
		let navBarHeight = navBar.offsetHeight;
		if (navBarStyle['marginTop']) {
			navBarHeight += parseFloat(navBarStyle['marginTop']);
		}
		if (navBarStyle['marginBottom']) {
			navBarHeight += parseFloat(navBarStyle['marginBottom']);
		}
		if (navBarStyle['paddingTop']) {
			navBarHeight += parseFloat(navBarStyle['paddingTop']);
		}
		if (navBarStyle['paddingBottom']) {
			navBarHeight += parseFloat(navBarStyle['paddingBottom']);
		}
		window.scrollTo({ top: window.pageYOffset + sectionHeaderElement.getBoundingClientRect().top - navBarHeight - topOffset, behavior: 'smooth' });
	}
}

/**
 * Go through the menu item and its nested options to get the allergens/diets filters used by the restaurant
 *
 * @param {string[]} currentFilters
 * @param {string} key
 * @param {MenuItem | Option} item
 * @returns  {string[]} - List of the diets/allergens
 */
export function generateAvailableFilters(currentFilters: string[], key: 'diets'|'allergens', item: MenuItem|Option): string[] {
	let mutableCurrentFilters: string[] = [...currentFilters];
	item[key] && item[key]?.split(',').forEach((diet: string) => {
		if (!mutableCurrentFilters.includes(diet)) {
			mutableCurrentFilters.push(diet)
		}

		if (item.options?.length) {
			item.options.forEach((option: OptionGroup) => {
				if (option.values && option.values.length) {
					option.values.forEach((value: Option) => {
						mutableCurrentFilters = generateAvailableFilters(mutableCurrentFilters, key, value);
					})
				}
			})
		}
	})
	return mutableCurrentFilters;
}

/**
 * Check if the sku or id of the available element matches the sku
 *
 * @param {{ id: number; sku: string|null; unavailable?: boolean; }} element - Can be a menu item, option or sub-option
 * @param {string|null} sku - If it starts with 'id-' then it is an id, otherwise it is a sku
 * @return {boolean}
 */
export function getElementBySkuOrId(element: { id: number; sku?: string|null; unavailable?: boolean; }, sku?: string|null): boolean {
	if (!sku || element.unavailable) {
		return false;
	}

	if (!element.sku && !sku.startsWith('id-')) {
		return false;
	}

	return sku.startsWith('id-') ? element.id === +sku.split('id-')[1] : element.sku === sku;
}